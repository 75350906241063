import * as WiIcons from 'react-icons/wi'
import battery5to0 from '../assets/images/mapImages/Popup/Battery/0.svg'
import battery10to5 from '../assets/images/mapImages/Popup/Battery/5.svg'
import battery15to10 from '../assets/images/mapImages/Popup/Battery/10.svg'
import battery20to15 from '../assets/images/mapImages/Popup/Battery/15.svg'
import battery25to20 from '../assets/images/mapImages/Popup/Battery/20.svg'
import battery30to25 from '../assets/images/mapImages/Popup/Battery/25.svg'
import battery35to30 from '../assets/images/mapImages/Popup/Battery/30.svg'
import battery40to35 from '../assets/images/mapImages/Popup/Battery/35.svg'
import battery45to40 from '../assets/images/mapImages/Popup/Battery/45.svg'
import battery50to45 from '../assets/images/mapImages/Popup/Battery/50.svg'
import battery55to50 from '../assets/images/mapImages/Popup/Battery/55.svg'
import battery65to55 from '../assets/images/mapImages/Popup/Battery/65.svg'
import battery75to65 from '../assets/images/mapImages/Popup/Battery/75.svg'
import battery85to75 from '../assets/images/mapImages/Popup/Battery/85.svg'
import battery95to85 from '../assets/images/mapImages/Popup/Battery/95.svg'
import battery100to95 from '../assets/images/mapImages/Popup/Battery/100.svg'
import fuel from '../assets/images/mapImages/Popup/Icons/Fuel.svg'

export function capitalizeFirstLetter(string) {
  if (!string) return string
  return string[0].toUpperCase() + string.substring(1)
}

// Timestamp in seconds from unix epoch
export function unixTimestamp() {
  return Math.floor(Date.now() / 1000)
}

// dedicated for battery and for fuel
export function choosePopupBatteryImage(batteryValue, vehiclePropulsionType) {
  if (vehiclePropulsionType !== 'electric') {
    return `url(${fuel})`
  }

  if (batteryValue >= 0 && batteryValue < 5) {
    return `url(${battery5to0})`
  } else if (batteryValue >= 5 && batteryValue < 10) {
    return `url(${battery10to5})`
  } else if (batteryValue >= 10 && batteryValue < 15) {
    return `url(${battery15to10})`
  } else if (batteryValue >= 15 && batteryValue < 20) {
    return `url(${battery20to15})`
  } else if (batteryValue >= 20 && batteryValue < 25) {
    return `url(${battery25to20})`
  } else if (batteryValue >= 25 && batteryValue < 30) {
    return `url(${battery30to25})`
  } else if (batteryValue >= 30 && batteryValue < 35) {
    return `url(${battery35to30})`
  } else if (batteryValue >= 35 && batteryValue < 40) {
    return `url(${battery40to35})`
  } else if (batteryValue >= 40 && batteryValue < 45) {
    return `url(${battery45to40})`
  } else if (batteryValue >= 45 && batteryValue < 50) {
    return `url(${battery50to45})`
  } else if (batteryValue >= 50 && batteryValue < 55) {
    return `url(${battery55to50})`
  } else if (batteryValue >= 55 && batteryValue < 65) {
    return `url(${battery65to55})`
  } else if (batteryValue >= 65 && batteryValue < 75) {
    return `url(${battery75to65})`
  } else if (batteryValue >= 75 && batteryValue < 85) {
    return `url(${battery85to75})`
  } else if (batteryValue >= 85 && batteryValue < 95) {
    return `url(${battery95to85})`
  } else {
    return `url(${battery100to95})`
  }
}

export function mapStyleSelectName(style) {
  let styleName
  switch (style) {
    case 'dawn':
      styleName = (
        <>
          <WiIcons.WiHorizon /> Morning
        </>
      )
      break
    case 'day':
      styleName = (
        <>
          <WiIcons.WiDaySunny /> Day
        </>
      )
      break
    case 'dusk':
      styleName = (
        <>
          <WiIcons.WiSolarEclipse /> Evening
        </>
      )
      break

    case 'night':
      styleName = (
        <>
          <WiIcons.WiMoonWaxingCrescent4 /> Night
        </>
      )
      break

    default:
      styleName = (
        <>
          <WiIcons.WiDaySunny /> Day
        </>
      )
      break
  }

  return styleName
}
