import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getLocationsData = createAsyncThunk('locations/getLocationsData', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.REACT_APP_MAP_SERVER_PUBLIC_ACCESS_API_TOKEN,
      },
    }

    const response = await axios.get(`${process.env.REACT_APP_MAP_SERVER_API_URL}/locations/getLocations`, config)

    const responseData = response.data.payload
    return responseData.locations
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue({ data: error.response.data, status: error.response.status })
    } else {
      return rejectWithValue(error.message)
    }
  }
})
