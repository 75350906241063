// Permission check middleware
export const permissionCheckMiddleware = store => next => action => {
  if (action.type.endsWith('/rejected') && action.payload) {
    // Check if the error is 403 (Forbidden)
    if (action.payload.status === 403) {
      return null // Terminate the action here
    }
    return null // Terminate the action here
  }
  // Pass the action to the next middleware or reducer
  return next(action)
}
