import { useDispatch } from 'react-redux'
import { setLocation } from '../../store/slices/mapLocations/locationsSlice'
import * as RiIcons from 'react-icons/ri'

//Import styles
import '../../assets/styles/selectLocation/selectLocationResult.css'

export const SelectLocationResult = ({ result, mapRefData, index, currentSelectedLocationIndex, setCurrentSelectedLocationIndex }) => {
  const dispatch = useDispatch()

  return (
    <div
      className={`select-location-result ${currentSelectedLocationIndex === index ? 'active' : 'inactive'}`}
      onMouseDown={() => {
        setCurrentSelectedLocationIndex(index)
        dispatch(setLocation(index))
        mapRefData.flyTo({
          center: [result.location.longitude, result.location.latitude],
          zoom: result.zoom,
          duration: 6000,
          essential: true,
        })
      }}
    >
      <>
        <RiIcons.RiRoadMapLine />
        {result.name}
      </>
    </div>
  )
}
