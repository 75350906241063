//Import components
import { Route, Routes } from 'react-router-dom'
import Layout from './components/Layout'
import Map from './pages/Map'
import Error from './pages/Error'

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="*" element={<Error />} />
        <Route index element={<Map />} />
        <Route path="map" element={<Map />} />
      </Route>
    </Routes>
  )
}
