import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getVehiclesData = createAsyncThunk('vehicles/getVehiclesData', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.REACT_APP_MAP_SERVER_PUBLIC_ACCESS_API_TOKEN,
      },
    }

    const response = await axios.get(`${process.env.REACT_APP_MAP_SERVER_API_URL}/vehicles/getAllAvailableVehicles`, config)

    const responseData = response.data.payload.vehicles

    const markers = responseData
      .filter(
        vehicle =>
          (vehicle.current_location?.longitude && vehicle.current_location?.latitude) ||
          (vehicle.prev_current_location?.longitude && vehicle.prev_current_location?.latitude) ||
          (vehicle.last_event_location?.longitude && vehicle.last_event_location?.latitude)
      ) // include data only with current_location or last_event_location coordinates
      .filter(vehicle => {
        const timestamp = vehicle.last_event_time * 1000 || 0
        const fiveDays = 5 * 24 * 60 * 60 * 1000 // in milliseconds
        const isMoreThan5Days = Date.now() - timestamp > fiveDays
        const isLowBattery = vehicle.battery_pct * 100 < 10 // if battery is lower than 10%
        return !isMoreThan5Days && !isLowBattery // Do not include data which has not been updated for more than 5 days and low battery
      })
      .map(vehicle => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [vehicle.current_location.longitude, vehicle.current_location.latitude],
          },
          properties: {
            vehicle_id: vehicle.vehicle_id,
            battery_pct: vehicle.battery_pct,
            vehicle_type: vehicle.vehicle_type,
            propulsion_type: vehicle.propulsion_type,
            last_event_time: vehicle.last_event_time,
          },
        }
      })

    return {
      type: 'FeatureCollection',
      totalMarkers: markers.length,
      features: markers,
    }
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue({ data: error.response.data, status: error.response.status })
    } else {
      return rejectWithValue(error.message)
    }
  }
})
