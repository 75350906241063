import { createSlice } from '@reduxjs/toolkit'
import { getGeofencingZones } from './geofencingZonesActions'

const initialState = {
  loading: false,
  geofencingZonesData: {
    grayArea: {
      type: 'FeatureCollection',
      features: [],
    },
    speedLimitZones: {
      type: 'FeatureCollection',
      features: [],
    },
    noParkingZones: {
      type: 'FeatureCollection',
      features: [],
    },
    parkingZones: {
      type: 'FeatureCollection',
      features: [],
    },
  },
  error: null,
  success: false,
}

const geofencingZonesSlice = createSlice({
  name: 'geofencingZones',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getGeofencingZones.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(getGeofencingZones.fulfilled, (state, action) => {
        state.loading = false
        state.geofencingZonesData = action.payload
        state.success = true
      })
      .addCase(getGeofencingZones.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.success = false
      })
  },
})

//export const {  } = geofencingZonesSlice.actions

export default geofencingZonesSlice.reducer
