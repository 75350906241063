//Import components
import { configureStore } from '@reduxjs/toolkit'
import geofencingZonesReducer from '../store/slices/geofencingZones/geofencingZonesSlice'
import vehiclesReducer from '../store/slices/vehicles/vehiclesSlice'
import mapLocationsReducer from '../store/slices/mapLocations/locationsSlice'
import appReducer from '../store/slices/app/appSlice'
import { permissionCheckMiddleware } from './middlewares'

export const store = configureStore({
  reducer: {
    app: appReducer,
    geofencingZones: geofencingZonesReducer,
    vehicles: vehiclesReducer,
    mapLocations: mapLocationsReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: { warnAfter: 128 },
    }).concat(permissionCheckMiddleware),
  devTools: true,
})
