//Import components
import { SelectLocationBar } from './SelectLocation/SelectLocationBar'
import { SelectMapStyleBar } from './SelectMapStyle/SelectMapStyleBar'

//Import styles
import '../assets/styles/mapControls.css'

// Map controls
export default function MapControls({ mapRefData }) {
  return (
    <div className="map-controls-container">
      <div className="map-controls-select-map-style-bar-container">
        <SelectMapStyleBar />
      </div>
      <div className="map-controls-select-location-bar-container">
        <SelectLocationBar mapRefData={mapRefData} />
      </div>
    </div>
  )
}
