//Import components
import React, { useEffect, useRef, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import { useDispatch, useSelector } from 'react-redux'
import { getGeofencingZones } from '../../store/slices/geofencingZones/geofencingZonesActions'
import { getVehiclesData } from '../../store/slices/vehicles/vehiclesAction'
import setGeofencingZonesLayer from './actions/setGeofencingZonesLayer'
import setMapMarkers from './actions/updateMarker/setMapMarkers'
import { getLocationsData } from '../../store/slices/mapLocations/locationsAction'

//Import styles
import 'mapbox-gl/dist/mapbox-gl.css'
import '../../assets/styles/vehiclesMap.css'
import setVehiclesLayer from './actions/setVehiclesLayer'

// Duck map
export default function VehiclesMap({ setMapRefData }) {
  const { REACT_APP_MAPBOX_PUBLIC_KEY } = process.env
  const { geofencingZonesData, error: getGeofencingZonesDataError } = useSelector(state => state.geofencingZones)
  const { vehiclesData, error: getVehiclesDataError } = useSelector(state => state.vehicles)
  const { mapStyle } = useSelector(state => state.app)

  const dispatch = useDispatch()
  const locationsDataRef = useRef()
  const locationIndexRef = useRef()
  const mapContainer = useRef()
  const map = useRef()
  const vehiclesLayerDataRef = useRef(JSON.parse(JSON.stringify(vehiclesData))) // Read latest vehicles data from redux
  const geofencingZonesLayersDataRef = useRef(geofencingZonesData) // Read latest vehicles data from redux
  const mapPopupVehiclesMarkersRef = useRef({}) // Html map markers (for popup)
  const closeMarkerPopupCenterTimeoutIdRef = useRef()
  const updateVehiclesDataTimeoutId = useRef({})
  const updateGeofencingZonesDataTimeoutId = useRef({})
  const updateVehiclesDataTimeoutDelayValue = useRef(10000) // Initial update interval
  const updateGeofencingZonesDataTimeoutDelayValue = useRef(15000) // Initial update interval
  const mapLng = useRef(28.853089275547035)
  const mapLat = useRef(47.0211152983203)
  const mapZoom = useRef(12) // 15.44
  const mapPitch = useRef(0) // 37
  const mapBearing = useRef(0)
  const [isStyleLoaded, setIsStyleLoaded] = useState(false)

  mapboxgl.accessToken = REACT_APP_MAPBOX_PUBLIC_KEY

  // Updates the geofencing zones data ref when geofencingZonesData redux state changes
  useEffect(() => {
    geofencingZonesLayersDataRef.current = geofencingZonesData
  }, [geofencingZonesData])

  // Updates the vehicles data ref when vehiclesData redux state changes and create html marker with popup or update existing
  useEffect(() => {
    vehiclesLayerDataRef.current = JSON.parse(JSON.stringify(vehiclesData))
  }, [vehiclesData])

  // Show error during obtaining data
  useEffect(() => {
    if (getGeofencingZonesDataError) {
      clearTimeout(updateGeofencingZonesDataTimeoutId.current) // Clear get zones data interval on error
    }
    if (getVehiclesDataError) {
      clearTimeout(updateVehiclesDataTimeoutId.current) // Clear get vehicles data interval on error
    }
  }, [getGeofencingZonesDataError, getVehiclesDataError])

  // Get initial data
  useEffect(() => {
    dispatch(getLocationsData()) // Get locations list
  }, [dispatch])

  // Get geofencing zones data, run interval for get data again
  useEffect(() => {
    getNewGeofencingZonesData()

    // Get geofencing zones data
    function getNewGeofencingZonesData() {
      dispatch(getGeofencingZones()) // Get initial data

      if (getGeofencingZonesDataError) {
        clearTimeout(updateGeofencingZonesDataTimeoutId.current)
      } // Clear interval on error

      updateGeofencingZonesDataTimeoutId.current = setTimeout(() => {
        getNewGeofencingZonesData()
      }, updateGeofencingZonesDataTimeoutDelayValue.current)
    }

    // Cleanup function
    return () => {
      clearTimeout(updateGeofencingZonesDataTimeoutId.current) // Clear on unmount interval for get vehicles data
    }
    // eslint-disable-next-line
  }, [])

  // Get vehicles data, run interval for get data again
  useEffect(() => {
    getNewVehiclesData()

    // Get vehicles data
    function getNewVehiclesData() {
      dispatch(getVehiclesData()) // Get initial data

      if (getVehiclesDataError) {
        clearTimeout(updateVehiclesDataTimeoutId.current)
      } // Clear interval on error

      updateVehiclesDataTimeoutId.current = setTimeout(() => {
        getNewVehiclesData()
      }, updateVehiclesDataTimeoutDelayValue.current)
    }

    // Cleanup function
    return () => {
      clearTimeout(updateVehiclesDataTimeoutId.current) // Clear on unmount interval for get vehicles data
    }
    // eslint-disable-next-line
  }, [])

  // Listen to light mode change and update style
  useEffect(() => {
    // Wait for map to initialize and finish of map loading
    if (map.current) {
      map.current.setStyle(`mapbox://styles/mapbox/standard/?lightPreset=${mapStyle}`, { diff: false })
      map.current.on('style.load', setMapConfig)
      map.current.on('style.load', setNewMapLayers) // Triggered when `setStyle` is called
    }

    // Set map style config
    function setMapConfig() {
      map.current.setConfigProperty('basemap', 'lightPreset', `${mapStyle}`)
    }

    return () => {
      if (map.current) {
        map.current.off('style.load', setMapConfig)
        map.current.off('style.load', setNewMapLayers)
      }
    }
  }, [mapStyle])

  // Map initialisation
  useEffect(() => {
    // Initialize map only once
    if (!map.current) {
      // VehiclesMap initialisation
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/standard',
        center: [mapLng.current, mapLat.current],
        zoom: mapZoom.current,
        pitch: mapPitch.current, // pitch in degrees
        bearing: mapBearing.current, // bearing in degrees
        trackResize: true,
        optimizeForTerrain: true,
      })

      map.current.on('style.load', setMapConfig) // Set map config style (style and etc.)
      map.current.on('style.load', setNewMapLayers) // Initialize map zones layers

      setMapRefData(map.current)
    }

    // Set map style config
    function setMapConfig() {
      map.current.setConfigProperty('basemap', 'lightPreset', `${mapStyle}`)
    }

    // Cleanup function
    return () => {
      if (map.current) {
        map.current.off('style.load', setMapConfig)
        map.current.off('style.load', setNewMapLayers)
      }
    }
  }, [mapStyle, setMapRefData])

  // Get geofencing zones data, run interval for get data again
  useEffect(() => {
    // wait for map to initialize
    if (map.current && isStyleLoaded) {
      geofencingZones()
    }

    // Update geofencing zones layer
    function geofencingZones() {
      if (map.current.getSource('grayAreaSource') && geofencingZonesLayersDataRef.current?.grayArea) {
        map.current.getSource('grayAreaSource').setData(geofencingZonesLayersDataRef.current.grayArea) // Update geofencing zones layer source
      }
      if (map.current.getSource('speedLimitZonesSource') && geofencingZonesLayersDataRef.current?.speedLimitZones) {
        map.current.getSource('speedLimitZonesSource').setData(geofencingZonesLayersDataRef.current.speedLimitZones) // Update geofencing zones layer source
      }
      if (map.current.getSource('parkingZonesSource') && geofencingZonesLayersDataRef.current?.parkingZones) {
        map.current.getSource('parkingZonesSource').setData(geofencingZonesLayersDataRef.current.parkingZones) // Update geofencing zones layer source
      }
      if (map.current.getSource('noParkingZonesSource') && geofencingZonesLayersDataRef.current?.noParkingZones) {
        map.current.getSource('noParkingZonesSource').setData(geofencingZonesLayersDataRef.current.noParkingZones) // Update geofencing zones layer source
      }
    }
  }, [geofencingZonesData, isStyleLoaded])

  // Get vehicles data, run interval for get data again
  useEffect(() => {
    // Wait for map to initialize
    if (map.current && isStyleLoaded && vehiclesLayerDataRef.current && vehiclesLayerDataRef.current?.features?.length > 0) {
      setMapMarkers(
        dispatch,
        locationsDataRef,
        locationIndexRef,
        map,
        vehiclesLayerDataRef,
        mapPopupVehiclesMarkersRef,
        closeMarkerPopupCenterTimeoutIdRef
      ) // Update markers layer
    }
  }, [dispatch, vehiclesData, isStyleLoaded])

  // Set new map layers
  function setNewMapLayers() {
    if (!map.current?.getLayer('grayAreaLayer') && !map.current.getLayer('grayAreaOutlineLayer')) {
      setGeofencingZonesLayer(map.current, geofencingZonesLayersDataRef.current, 'grayAreaLayer')
    }
    if (!map.current?.getLayer('speedLimitZonesLayer') && !map.current?.getLayer('speedLimitZonesOutlineLayer')) {
      setGeofencingZonesLayer(map.current, geofencingZonesLayersDataRef.current, 'speedLimitZonesLayer')
    }
    if (!map.current?.getLayer('parkingZonesLayer') && !map.current?.getLayer('parkingZonesOutlineLayer')) {
      setGeofencingZonesLayer(map.current, geofencingZonesLayersDataRef.current, 'parkingZonesLayer')
    }
    if (!map.current?.getLayer('noParkingZonesLayer') && !map.current?.getLayer('noParkingZonesOutlineLayer')) {
      setGeofencingZonesLayer(map.current, geofencingZonesLayersDataRef.current, 'noParkingZonesLayer')
    }

    if (!map.current.getLayer('vehiclesLayer')) {
      setVehiclesLayer(map.current, vehiclesLayerDataRef.current)
    }

    setIsStyleLoaded(true)
  }

  return <div ref={mapContainer} className="map-container" />
}
