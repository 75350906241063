import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getGeofencingZones = createAsyncThunk('geofencingZones/getGeofencingZones', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.REACT_APP_MAP_SERVER_PUBLIC_ACCESS_API_TOKEN,
      },
    }

    const response = await axios.get(`${process.env.REACT_APP_MAP_SERVER_API_URL}/geofencingZones/getAllZones`, config)

    const responseData = response.data.payload.geofencingZones

    let geofencingZonesFeatures = {
      grayArea: {
        type: 'FeatureCollection',
        lastUpdated: responseData.lastUpdated,
        features: [],
      },
      speedLimitZones: {
        type: 'FeatureCollection',
        lastUpdated: responseData.lastUpdated,
        features: [],
      },
      noParkingZones: {
        type: 'FeatureCollection',
        lastUpdated: responseData.lastUpdated,
        features: [],
      },
      parkingZones: {
        type: 'FeatureCollection',
        lastUpdated: responseData.lastUpdated,
        features: [],
      },
    }

    responseData.forEach(zone => {
      if (zone.hasOwnProperty('zoneType')) {
        const zoneType = zone.zoneType
        const zoneData = zone.zoneData
        const lastUpdated = zone.lastUpdated

        // Filter zones
        if (zoneType === 'grayAreaZone') {
          geofencingZonesFeatures.grayArea.lastUpdated = lastUpdated
          geofencingZonesFeatures.grayArea.features.push(...zoneData)
        } else if (zoneType === 'speedLimitZone') {
          geofencingZonesFeatures.speedLimitZones.lastUpdated = lastUpdated
          geofencingZonesFeatures.speedLimitZones.features.push(...zoneData)
        } else if (zoneType === 'noParkingZone') {
          geofencingZonesFeatures.noParkingZones.lastUpdated = lastUpdated
          geofencingZonesFeatures.noParkingZones.features.push(...zoneData)
        } else if (zoneType === 'parkingZone') {
          geofencingZonesFeatures.parkingZones.lastUpdated = lastUpdated
          geofencingZonesFeatures.parkingZones.features.push(...zoneData)
        }
      }
    })

    return geofencingZonesFeatures
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue({ data: error.response.data, status: error.response.status })
    } else {
      return rejectWithValue(error.message)
    }
  }
})
