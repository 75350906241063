import battery15to30 from '../../../assets/images/mapImages/Markers/scooters/battery15to30.svg'
import battery30to80 from '../../../assets/images/mapImages/Markers/scooters/battery30to80.svg'
import battery80to100 from '../../../assets/images/mapImages/Markers/scooters/battery80to100.svg'
import discount35 from '../../../assets/images/mapImages/Markers/scooters/discount35.svg'
import carImage from '../../../assets/images/mapImages/Markers/cars/car.svg'

const markerImages = [
  { name: 'battery15to30Img', url: battery15to30 },
  { name: 'battery30to80Img', url: battery30to80 },
  { name: 'battery80to100Img', url: battery80to100 },
  { name: 'discount35Img', url: discount35 },
]

const markerCarImages = [{ name: 'carImg', url: carImage }]

function setVehicleImages(map, markerImages, width = 45, height = 45) {
  markerImages.forEach(image => {
    if (!map.hasImage(image.name)) {
      const customIcon = new Image(width, height)
      customIcon.onload = () => {
        map.addImage(image.name, customIcon)
      }
      customIcon.src = image.url // Ensure you use 'url' if it contains the path to the SVG
    }
  })
}

// Intended to add vehicles on the map (source and layer)
export default function setVehiclesLayer(map, vehiclesData) {
  setVehicleImages(map, markerImages)
  setVehicleImages(map, markerCarImages, 95, 35)

  // Add vehicles source
  map.addSource('vehiclesSource', {
    type: 'geojson',
    data: vehiclesData,
    cluster: false,
  })

  map.addLayer({
    id: 'vehiclesLayer',
    type: 'symbol',
    source: 'vehiclesSource',
    layout: {
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
      'symbol-z-order': 'source',
      'icon-size': 0.9,
      'icon-image': [
        'case',
        ['==', ['get', 'vehicle_type'], 'CAR'], // If vehicle_type is 'CAR'
        'carImg',
        ['==', ['get', 'isDiscount'], true], // Set discount image by last ride
        'discount35Img',
        ['all', ['>', ['*', ['coalesce', ['get', 'battery_pct'], 0], 100], 15], ['<', ['*', ['coalesce', ['get', 'battery_pct'], 0], 100], 30]],
        'battery15to30Img',
        ['all', ['>=', ['*', ['coalesce', ['get', 'battery_pct'], 0], 100], 30], ['<', ['*', ['coalesce', ['get', 'battery_pct'], 0], 100], 80]],
        'battery30to80Img',
        ['all', ['>=', ['*', ['coalesce', ['get', 'battery_pct'], 0], 100], 80], ['<=', ['*', ['coalesce', ['get', 'battery_pct'], 0], 100], 100]],
        'battery80to100Img',
        'battery15to30Img', // default
      ],
    },
    paint: {
      'icon-opacity': 1,
    },
    'layout-transition': {
      duration: 0,
      delay: 0,
    },
  })
}
