//Import components
import updateMarkerPosition from './updateMarkerPosition'
import markerPopup from './markerPopup'
import mapboxgl from 'mapbox-gl'
import { unixTimestamp } from '../../../../helpers/utils'

// Updates layer marker position using smooth animation
export default function setMapMarkers(
  dispatch,
  locationsDataRef,
  locationIndexRef,
  mapRef,
  vehiclesLayerDataRef,
  mapPopupVehiclesMarkersRef,
  closeMarkerPopupCenterTimeoutIdRef
) {
  for (let singleMarkerData of vehiclesLayerDataRef.current.features) {
    const coords = singleMarkerData.geometry.coordinates
    const props = singleMarkerData.properties
    const markerId = props.vehicle_id
    const lastRideTime = props.last_event_time || 0 // in seconds
    let mapPopupVehicleMarker // invisible, dedicated for popup only

    const lastRideLimitTime = 24 * 60 * 60 // 24 hours in seconds
    const isLastRideAlongTimeAgo = unixTimestamp() - lastRideTime > lastRideLimitTime
    singleMarkerData.properties.isDiscount = isLastRideAlongTimeAgo || false // set true if was not rides more than 24 hours

    // Update popup marker
    if (mapPopupVehiclesMarkersRef.current[markerId]) {
      mapPopupVehicleMarker = mapPopupVehiclesMarkersRef.current[markerId] // Get map marker

      updateMarkerPosition(mapPopupVehicleMarker, singleMarkerData, coords, markerId, mapRef, vehiclesLayerDataRef) // Update popup marker position on the map

      let popup = mapPopupVehicleMarker.getPopup()

      // Update popup content when it is opened
      if (popup.isOpen()) {
        popup.setDOMContent(markerPopup(singleMarkerData))
      }
    } else {
      // Create html marker on the map
      const markerEl = document.createElement('div')
      markerEl.className = 'map-marker'
      markerEl.dataset.type = props.type
      markerEl.style.zIndex = 'auto'

      // Create the popup
      const popup = new mapboxgl.Popup({
        offset: [0, -20],
        anchor: 'bottom',
        closeButton: false,
        closeOnClick: true,
        focusAfterOpen: false,
        keepInView: true,
      }).setMaxWidth('307px')

      mapPopupVehiclesMarkersRef.current[markerId] = new mapboxgl.Marker(markerEl).setLngLat(coords).setPopup(popup).addTo(mapRef.current)
      mapPopupVehicleMarker = mapPopupVehiclesMarkersRef.current[markerId] // Get map marker

      // Center map by marker coordinates when popup is opened
      const onPopupOpen = () => {
        if (!vehiclesLayerDataRef.current?.features?.length) {
          return
        }

        const markerExists = markerId => vehiclesLayerDataRef.current.features.find(feature => feature.properties.vehicle_id === markerId)

        if (!markerExists(markerId)) {
          return
        }

        popup.setDOMContent(markerPopup(singleMarkerData)) // Create popup content
        const popupHeight = popup.getElement().clientHeight // Get the height of the popup
        const markerLngLat = mapPopupVehicleMarker.getLngLat() // Get the center coordinates of the mapMarker
        const markerPixel = mapRef.current.project(markerLngLat) // Convert the mapMarker coordinates to pixel coordinates
        const verticalOffset = popupHeight / 2 // Calculate the vertical offset based on the popup height

        // Calculate the final target pixel coordinates to center the popup
        const targetPixel = {
          x: markerPixel.x,
          y: markerPixel.y - verticalOffset,
        }

        const targetLngLat = mapRef.current.unproject(targetPixel) // Convert the target pixel coordinates back to geographical coordinates
        clearTimeout(closeMarkerPopupCenterTimeoutIdRef.current) // Clear timout for map center after marker popup close

        // Center the map to the target coordinates using `flyTo`
        mapRef.current.flyTo({
          center: targetLngLat,
          zoom: mapRef.current.getZoom(), // Maintain the current zoom level
          bearing: mapRef.current.getBearing(), // Maintain the current map rotation
          duration: 2000,
          essential: true,
        })
      }

      // Center map by default coordinates when popup is closed
      const onPopupClose = () => {
        // Exit if no location data
        if (!locationsDataRef?.current) {
          return
        }

        const longitude = locationsDataRef.current[Number(locationIndexRef.current)].location.longitude
        const latitude = locationsDataRef.current[Number(locationIndexRef.current)].location.latitude
        const zoom = locationsDataRef.current[Number(locationIndexRef.current)].zoom

        clearTimeout(closeMarkerPopupCenterTimeoutIdRef.current) // Clear timout for map center after marker popup close

        closeMarkerPopupCenterTimeoutIdRef.current = setTimeout(function () {
          mapRef.current.flyTo({
            center: [longitude, latitude],
            zoom: zoom,
            duration: 2000,
            essential: true,
          })
        }, 5000)
      }

      // Add event listeners to the popup
      popup.on('open', onPopupOpen)
      popup.on('close', onPopupClose)
    }

    // Update vehicles layer
    if (mapRef.current && vehiclesLayerDataRef.current.features?.length > 0 && mapRef.current.getSource('vehiclesSource')) {
      mapRef.current.getSource('vehiclesSource').setData(vehiclesLayerDataRef.current)
    }
  }
}
