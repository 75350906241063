// Import components
import { capitalizeFirstLetter, choosePopupBatteryImage } from '../../../../helpers/utils'

//Import images
import Pin from '../../../../assets/images/mapImages/Popup/Icons/Pin.svg'
import Play from '../../../../assets/images/mapImages/Popup/Icons/Play.svg'

// Intended to create or update popup when we press on the marker
export default function markerPopup(markerData) {
  const markerProperties = markerData?.properties // single marker properties
  const markerLongitude = markerData?.geometry?.coordinates[0]
  const markerLatitude = markerData?.geometry?.coordinates[1]
  const vehicleId = markerProperties.vehicle_id
  const batteryValue = markerProperties.battery_pct * 100
  let vehicleTypeValue = markerProperties.vehicle_type
  const vehiclePropulsionTypeValue = markerProperties.propulsion_type || 'electric'

  let vehicleIdContainer
  let popupContentContainer
  let batteryContainer, batteryValueEl, batteryImgValueEl
  let lastLocationContainer, lastLocationImgValueEl, lastLocationEl, lastLocationLinkEl
  let startRideBtnContainer, startRideBtnImgValueEl, startRideBtnTextEl

  let popupContainer = document.getElementById(`popup-${vehicleId}`)

  if (!popupContainer) {
    popupContainer = document.createElement('div')
    popupContainer.id = `popup-${vehicleId}`
    popupContainer.className = 'popup-container'

    vehicleIdContainer = document.createElement('div')
    vehicleIdContainer.className = 'vehicle-id-container'
    vehicleIdContainer.innerHTML = `Vehicle: ${vehicleId}`

    popupContentContainer = document.createElement('div')
    popupContentContainer.className = 'popup-content-container'

    batteryContainer = document.createElement('div')
    batteryContainer.className = 'battery-container'
    batteryImgValueEl = document.createElement('div')
    batteryImgValueEl.className = 'battery-img-value'
    batteryValueEl = document.createElement('div')
    batteryValueEl.className = 'battery-value'
    batteryValueEl.textContent = `Battery: ${Math.round(batteryValue)}%`

    lastLocationContainer = document.createElement('div')
    lastLocationContainer.className = 'last-location-container'
    lastLocationImgValueEl = document.createElement('div')
    lastLocationImgValueEl.className = 'last-location-img-value'
    lastLocationEl = document.createElement('div')
    lastLocationEl.className = 'last-location-value'
    lastLocationEl.innerHTML = `Location:`
    lastLocationLinkEl = document.createElement('a')
    lastLocationLinkEl.className = 'last-location-link'

    startRideBtnContainer = document.createElement('a')
    startRideBtnContainer.className = 'start-ride-btn-container'
    startRideBtnTextEl = document.createElement('div')
    startRideBtnTextEl.className = 'start-ride-btn-text-value'
    startRideBtnTextEl.innerHTML = `Start ride`
    startRideBtnImgValueEl = document.createElement('div')
    startRideBtnImgValueEl.className = 'start-ride-btn-img-value'

    popupContainer.appendChild(vehicleIdContainer)
    popupContainer.appendChild(popupContentContainer)

    popupContentContainer.appendChild(batteryContainer)
    batteryContainer.appendChild(batteryImgValueEl)
    batteryContainer.appendChild(batteryValueEl)

    popupContentContainer.appendChild(lastLocationContainer)
    lastLocationContainer.appendChild(lastLocationImgValueEl)
    lastLocationContainer.appendChild(lastLocationEl)
    lastLocationContainer.appendChild(lastLocationLinkEl)

    popupContentContainer.appendChild(startRideBtnContainer)
    startRideBtnContainer.appendChild(startRideBtnTextEl)
    startRideBtnContainer.appendChild(startRideBtnImgValueEl)
  } else {
    vehicleIdContainer = popupContainer.querySelector('.vehicle-id-container')
    popupContentContainer = popupContainer.querySelector('.popup-content-container')

    batteryContainer = popupContentContainer.querySelector('.battery-container')
    batteryImgValueEl = batteryContainer.querySelector('.battery-img-value')
    batteryValueEl = batteryContainer.querySelector('.battery-value')

    lastLocationContainer = popupContentContainer.querySelector('.last-location-container')
    lastLocationImgValueEl = lastLocationContainer.querySelector('.last-location-img-value')
    lastLocationLinkEl = lastLocationContainer.querySelector('.last-location-link')

    startRideBtnContainer = popupContentContainer.querySelector('.start-ride-btn-container')
    startRideBtnImgValueEl = startRideBtnContainer.querySelector('.start-ride-btn-img-value')
  }

  if (vehicleTypeValue && typeof vehicleTypeValue === 'string') {
    vehicleTypeValue = vehicleTypeValue?.toLowerCase()
    vehicleTypeValue = capitalizeFirstLetter(vehicleTypeValue)
    if (vehiclePropulsionTypeValue === 'electric') {
      vehicleTypeValue = 'E-' + vehicleTypeValue
    }
    vehicleIdContainer.innerHTML = `${vehicleTypeValue}: ${vehicleId}`
  }

  // Change battery image by battery value
  batteryImgValueEl.style.backgroundImage = choosePopupBatteryImage(batteryValue, vehiclePropulsionTypeValue)

  if (vehiclePropulsionTypeValue === 'electric') {
    batteryValueEl.innerHTML = `Battery: ${Math.round(batteryValue)}%`
  } else {
    batteryValueEl.innerHTML = `Fuel: ${Math.round(batteryValue)}%`
  }

  lastLocationImgValueEl.style.backgroundImage = `url(${Pin})`
  lastLocationLinkEl.href = `https://www.google.com/maps/place/${markerLatitude},${markerLongitude}`
  lastLocationLinkEl.rel = 'noopener noreferrer'
  lastLocationLinkEl.target = '_blank'
  lastLocationLinkEl.textContent = 'Click'

  startRideBtnImgValueEl.style.backgroundImage = `url(${Play})`
  startRideBtnContainer.href = process.env.REACT_APP_MAP_POPUP_START_RIDE_URL + `/${vehicleId.replace(/ /g, '%20')}`
  startRideBtnContainer.rel = 'noopener noreferrer'
  startRideBtnContainer.target = '_blank'

  return popupContainer
}
