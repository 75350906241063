//Import components
import { SelectMapStyleResult } from './SelectMapStyleResult'

//Import styles
import '../../assets/styles/selectMapStyle/selectMapStyleResultsList.css'

export const SelectMapStyleResultsList = ({ results, currentSelectedMapStyleIndex, setCurrentSelectedMapStyleIndex }) => {
  return (
    <div className="select-map-style-results-list">
      {results.map((result, id) => {
        return (
          <SelectMapStyleResult
            result={result}
            index={id}
            currentSelectedMapStyleIndex={currentSelectedMapStyleIndex}
            setCurrentSelectedMapStyleIndex={setCurrentSelectedMapStyleIndex}
            key={id}
          />
        )
      })}
    </div>
  )
}
