//Import components
import VehiclesMap from '../components/VehiclesMap/VehiclesMap'
import { useState } from 'react'
import MapControls from '../components/MapControls'

export default function Map() {
  const [mapRefData, setMapRefData] = useState()

  return (
    <>
      <VehiclesMap setMapRefData={setMapRefData} />
      <MapControls mapRefData={mapRefData} />
    </>
  )
}
