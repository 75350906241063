//Import components
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { SelectMapStyleResultsList } from './SelectMapStyleResultsList'
import { mapStyleSelectName } from '../../helpers/utils'

//Import styles
import '../../assets/styles/selectMapStyle/selectMapStyleBar.css'

export const SelectMapStyleBar = () => {
  const { mapStyle } = useSelector(state => state.app)
  const selectMapStyleResults = useRef(['dawn', 'day', 'dusk', 'night'])
  const [opened, setOpened] = useState(false)
  const [currentSelectedMapStyleIndex, setCurrentSelectedMapStyleIndex] = useState(selectMapStyleResults.current.indexOf(mapStyle || 'day'))
  const dropDownRef = useRef()

  useEffect(() => {
    let handler = e => {
      if (!dropDownRef.current.contains(e.target)) {
        setOpened(false)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  })

  function triggerHandleClick() {
    setOpened(prevOpened => !prevOpened)

    if (!opened && mapStyle) {
      setCurrentSelectedMapStyleIndex(selectMapStyleResults.current?.indexOf(mapStyle))
    }
  }

  return (
    <>
      <div ref={dropDownRef} className="select-map-style-dropdown-wrapper" onMouseDown={() => triggerHandleClick()}>
        <div className="select-map-style-dropdown-wrapper-name">
          {mapStyle ? mapStyleSelectName(selectMapStyleResults.current[currentSelectedMapStyleIndex]) : 'Map style'}
        </div>
      </div>
      {opened && (
        <SelectMapStyleResultsList
          results={selectMapStyleResults.current}
          currentSelectedMapStyleIndex={currentSelectedMapStyleIndex}
          setCurrentSelectedMapStyleIndex={setCurrentSelectedMapStyleIndex}
        />
      )}
    </>
  )
}
