import { useDispatch } from 'react-redux'
import { setMapStyle } from '../../store/slices/app/appSlice'
import { mapStyleSelectName } from '../../helpers/utils'

//Import styles
import '../../assets/styles/selectMapStyle/selectMapStyleResult.css'

export const SelectMapStyleResult = ({ result, index, currentSelectedMapStyleIndex, setCurrentSelectedMapStyleIndex }) => {
  const dispatch = useDispatch()

  return (
    <div
      className={`select-map-style-result ${currentSelectedMapStyleIndex === index ? 'active' : 'inactive'}`}
      onMouseDown={() => {
        setCurrentSelectedMapStyleIndex(index)
        dispatch(setMapStyle(result))
      }}
    >
      {mapStyleSelectName(result)}
    </div>
  )
}
