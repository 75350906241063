import { createSlice } from '@reduxjs/toolkit'
import { getVehiclesData } from './vehiclesAction'

const initialState = {
  loading: false,
  vehiclesData: {
    type: 'FeatureCollection',
    features: [],
  },
  error: null,
  success: false,
}

const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getVehiclesData.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(getVehiclesData.fulfilled, (state, action) => {
        state.loading = false
        state.vehiclesData = action.payload
        state.success = true
      })
      .addCase(getVehiclesData.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.success = false
      })
  },
})

//export const {  } = vehiclesSlice.actions

export default vehiclesSlice.reducer
