import { createSlice } from '@reduxjs/toolkit'

// Initialize from local storage
const mapStyle = localStorage.getItem('mapStyle') ? localStorage.getItem('mapStyle') : 'day'

const initialState = {
  loading: false,
  mapStyle: mapStyle,
  error: null,
  success: false,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMapStyle: (state, action) => {
      state.mapStyle = action.payload
      localStorage.setItem('mapStyle', action.payload) // store application map style
    },
  },
})

export const { setMapStyle } = appSlice.actions

export default appSlice.reducer
